<template>
  <div class="policy">
    <Header />
    <div class="content-inner">
      <Tab />
      <div class="main-inner">
        <h1>小楼东用户隐私政策</h1>
        <p>本政策适用于杭州小楼东科技有限公司的产品及相关服务。</p>
        <p>
          您所使用的“小楼东”应用的运营者的公司名称为杭州小楼东科技有限公司，注册地址为：西湖区马塍路36号火炬高新科技园一号楼八楼802室，个人信息保护相关负责人联系方式为：privacy@xiaoloudong.com。
        </p>
        <p>最近更新时间：2020.11.1。</p>
        <p>生效时间：2020.11.1。</p>
        <p>本隐私政策将帮助您了解以下内容：</p>
        <ul>
          <li>0、引言</li>
          <li>1、我们如何收集和使用您的个人信息</li>
          <li>2、我们如何使用Cookie、同类技术和SDK技术</li>
          <li>3、我们如何共享、转让、公开披露您的个人信息</li>
          <li>4、我们如何保护您的个人信息</li>
          <li>5、您如何管理您的个人信息/您的权利</li>
          <li>6、我们如何处理未成年人的个人信息</li>
          <li>7、存储信息的地点和期限，您的个人信息如何在全球范围转移</li>
          <li>8、本隐私政策如何更新</li>
          <li>9、如何联系我们/用户申诉渠道和反馈机制</li>
          <li>10、广告/我们向您发送的信息</li>
          <li>11、适用范围</li>
        </ul>
        <p>
          杭州小楼东科技有限公司（以下统称“我们”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护 措施来保护您的个人信息。
        </p>
        <p>请在使用我们的产品、服务前，仔细阅读并了解本《隐私政策》。</p>

        <h4>0、引言</h4>
        <p>小楼东对其收集的用户信息严格保密，并建立健全用户信息保护制度，以便为每一位用户提供可靠、放心的服务：</p>
        <ul>
          <li>1、清晰明确：我们将尽可能采用通俗易懂的文字为您阐释隐私政策，以便您能更清晰地了解我们如何保护和处理所收集的您的信息。</li>
          <li>2、保障权益：我们将采取充分、合理的措施保障您的隐私权益。</li>
          <li>3、尊重用户：我们尊重用户的选择权、知情权等权利的自由行使，允许您管理您的个人信息。</li>
          <li>4、必要安全：我们将仅收取必要的信息，并利用这些信息为您提供安全、详实的服务。</li>
          <li>5、及时贴心：我们将随时聆听您的询问，并尽快为您解答您的疑问。</li>
        </ul>
        <p>
          希望您仔细阅读《隐私政策》（以下简称“本政策”），详细了解我们对信息的收集、使用方式，以便您更好地了解我们的服务并在是否选择服务以及选择何种服务时更加符合您的真实意思表示。
        </p>
        <p>请您在注册或使用我们的服务前仔细阅读本政策，若您使用我们提供的服务，即表示您认同我们在本政策中所述内容。</p>
        <p>如您有问题，请联系我们。</p>
        <p>为更好的说明，本政策中的下列概念是指：</p>
        <ul>
          <li>
            个人常用设备信息：指包括硬件序列号、设备MAC地址、软件列表、唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等）等在内的描述个人常用设备基本情况的信息。
          </li>
          <li>个人位置信息：指包括行踪轨迹、精准定位信息、住宿信息、经纬度等。</li>
        </ul>

        <h4>1、我们如何收集和使用您的个人信息</h4>
        <p>
          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，我们会出于本政策所述的以下目的，收集和使用您的个人信息：
        </p>
        <p>收集个人信息的业务功能以及收集的个人信息 我们将向您提供下列服务，并在提供下列服务时分别收集如下所列的信息：</p>
        <p>
          1.业务功能：注册成为用户。当您注册小楼东服务时，为完成创建账号，您需要提供以下信息供我们收集：手机权限、手机号码或微信授权、昵称、生日、性别，收集这些信息是为了帮助你完成小楼东注册，获取小楼东的账号，并以此保护您小楼东帐号的安全。 为保障平台安全和运营安全，我们会申请系统权限收集手机号码、IMEI、IMSI、本机应用安装列表等设备信息用于平台安全风控
          手机号码属于敏感信息，收集此类信息是为了满足国家法律法规的网络实名制要求以及完成小楼东账号的注册。 若您不提供这类信息，您可能无法正常使用我们的服务。 您提供的上述信息，将在您使用本服务期间持续授权我们使用。我们会一直保存您的信息直至您注销账号。在您注销账号时，我们将停止使用并删除上述信息。 上述信息仅存储于中华人民共和国境内，如需跨境传输，我们将会单独征得您的授权同意。
        </p>
        <p>
          2.业务功能：聊天。当您使用聊天服务时，我们没有要求您必须提供的信息。如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：相册权限、麦克风权限。但如果您不提供这些信息，将不会影响使用本服务的基本业务功能。 您提供的上述信息，将在您使用本服务期间持续授权我们使用。我们会一直保存您的信息直至您注销账号。在您注销账号时，我们将停止使用并删除上述信息。
          上述信息仅存储于中华人民共和国境内，如需跨境传输，我们将会单独征得您的授权同意。
        </p>
        <p>
          3.支付功能：支付功能。支付功能由与我们合作的第三方支付机构向您提供服务，第三方支付机构将会需要收集您的信息，我们将无法获取您的信息。拒绝提供信息仅会使您无法使用上述支付功能，但不影响您使用小楼东的其他功能。
        </p>
        <p>
          4、业务功能：真人实名认证。当您选择真人实名认证服务时，为确认您所提供的身份信息为您本人信息且属实，需要您提供以下信息供我们收集：真实姓名、身份证号及身份证核验授权，个人生物识别信息中的面部特征信息。 个人生物识别信息中的面部特征信息属于敏感信息，收集此类信息是为了满足国家法律法规的网络实名制要求，同时保证小楼东产品真实安全的社交氛围。若您不提供这类信息，您可能无法正常使用小楼东认证服务。
          您提供的上述信息，将在您使用本服务期间持续授权我们使用。我们会一直保存您的信息直至您注销账号或者您主动取消真人实名认证的功能。在您注销账号或者您主动取消真人实名认证的功能时，我们将停止使用并删除上述信息。 上述信息仅存储于中华人民共和国境内，如需跨境传输，我们将会单独征得您的授权同意。 用户画像、个性化展示的说明
          为了实现个性化推荐的功能，我们会根据上述功能中所收集的信息进行综合统计并通过算法做特征与偏好性分析，用以向你推送可能感兴趣的其他用户。 我们如何使用收集的信息 我们将按照前述的用途使用您的信息。当我们要将信息用于本政策未载明的其他用途时，会事先征得您的同意。当我们要将基于特定目的收集的信息用于其他目的时，会事先征得您的同意。 您分享的信息 您可以通过我们的服务与您的其他用户分享您的相关信息。
          请注意，这其中可能包含您的个人身份信息、个人财产信息等敏感信息。请您谨慎考虑披露您的相关个人敏感信息。 您可以通过服务中的设置或我们提供的指引删除您公开分享的信息。但请您注意，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立地保存。
        </p>

        <h4>2、我们如何使用Cookie、同类技术和SDK技术</h4>
        <h3>Cookie</h3>
        <p>
          为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们可能会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。您可以在浏览器中进行相应的数据清除操作。
        </p>
        <h3>其他技术</h3>
        <p>
          除Cookie外,我们还会在网站上使用其他同类技术。我们向您发送的会员短信通知或小楼东小助手站内通知可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </p>
        <h3>SDK技术</h3>
        <p>
          SDK技术由与我们合作的第三方机构提供，我们仅能知悉其要求获得授权的权限。第三方机构将会需要收集您的信息，我们无法获取您的信息，除非第三方机构明确向我们披露的要收集的敏感信息。第三方机构要求获得的权限以及明确向我们披露的收集的敏感信息，详见《SDK技术说明文档》。 第三方机构拒绝提供信息仅会使您无法使用第三方机构提供的技术所搭载的功能，但不影响您使用小楼东的其他功能。
        </p>

        <h4>3、我们如何共享、转让、公开披露您的个人信息</h4>
        <h3>共享</h3>
        <p>我们不会与小楼东服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</p>
        <ul>
          <li>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；</li>
          <li>2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；</li>
          <li>
            3、与关联公司间共享：为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护小楼东关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息（如为便于您通过小楼东账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意；
          </li>
          <li>
            4、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。
            我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。 对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
          </li>
        </ul>
        <h3>转让</h3>
        <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <ul>
          <li>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</li>
          <li>
            2、在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
          </li>
        </ul>
        <h3>公开披露</h3>
        <p>我们仅会在以下情况，公开披露您的个人信息：</p>
        <ul>
          <li>1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</li>
          <li>
            2、如果我们确定您出现违反法律法规或严重违反小楼东相关协议规则的情况，或为保护小楼东及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或小楼东相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及小楼东已对您采取的措施。
          </li>
        </ul>

        <h3>共享、转让、公开披露个人信息时事先征得授权同意的例外</h3>
        <p>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
        <ul>
          <li>1、与国家安全、国防安全有关的；</li>
          <li>2、与公共安全、公共卫生、重大公共利益有关的；</li>
          <li>3、与犯罪侦查、起诉、审判和判决执行等有关的；</li>
          <li>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</li>
          <li>5、您自行向社会公众公开的个人信息；</li>
          <li>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</li>
        </ul>
        <p>
          根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>

        <h4>4、我们如何保护您的个人信息</h4>
        <p>为了保护您的信息安全，我们将严格遵守法律法规等规范性文件要求的技术措施和操作流程保护您的信息秘密，同时：</p>
        <p>
          1.我们已采取符合业界通用解决方案、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们建立严格的内部控制制度，建立完善、充分的管理流程，我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </p>
        <p>2.我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</p>
        <p>3.我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
        <p>
          4.互联网并非绝对安全的环境，我们强烈建议您不要使用非小楼东推荐的通信方式发送个人信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
        </p>
        <p>如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络小楼东客服，以便我们根据您的申请采取相应措施。</p>
        <p>
          请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在公众场合选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
        </p>
        <p>请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。</p>
        <p>
          我们将按照法律法规的要求更新并公开安全风险、个人信息安全影响评估等报告的内容。互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p>
          在不幸发生个人信息安全事件后，我们将依照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施，您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以推送通知的方式告知您，并采取合理有效的方式发送公告。
        </p>
        <p>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>

        <h4>5、您如何管理您的个人信息/您的权利</h4>
        <h3>个人信息查询</h3>
        <p>您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</p>
        <p>
          账户信息——如果您希望访问或编辑您的账户中的个人信息、设置隐私与权限、设置消息提醒与聊天、更改您的绑定手机号与密码、关闭您的账户等，您可以通过登录账号通过“更多”-“设置”执行此类操作。
        </p>
        <p>个人资料——如果您希望访问或编辑您个人资料中的昵称、头像、出生年月日、以及其他资料，您可以通过登录账户通过点击设置执行此类操作。</p>
        <p>对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据相关安排向您提供。 您可以通过与小楼东客服联系的方式，申请个人信息副本。</p>
        <h3>个人信息更正</h3>
        <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“个人信息查询”中列明的方式提出更正或补充申请。</p>
        <h3>个人信息删除</h3>
        <p>您可以通过本条“个人信息查询”中列明的方式删除您的部分个人信息。</p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>1、如果我们处理个人信息的行为违反法律法规；</p>
        <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
        <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
        <p>4、如果您不再使用我们的产品或服务，或您主动注销了账号；</p>
        <p>5、如果我们永久不再为您提供产品或服务。</p>
        <p>
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        </p>
        <h3>用户账号注销</h3>
        <p>您可以联系客服提交账户注销申请。 在您主动注销账户之后，我们将停止为您提供产品或服务，根据相应法律的要求删除您的个人信息，或使其匿名化处理。</p>
        <h3>撤回已同意的授权</h3>
        <p>每个业务功能需要一些基本的个人信息才能得以完成。除此之外，对于额外个人信息的收集和使用，您可以自行或与小楼东客服联系给予或收回您的授权同意。</p>
        <p>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。 约束信息系统自动决策</p>
        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害小楼东商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
        <p>响应您的上述请求 为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
        <p>我们将在15天内做出答复。如您对我们的答复不满意，还可以通过小楼东客服发起投诉。</p>
        <p>
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
        </p>
        <h3>相关限制</h3>
        <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <ul>
          <li>1、与国家安全、国防安全有关的；</li>
          <li>2、与公共安全、公共卫生、重大公共利益有关的；</li>
          <li>3、与犯罪侦查、起诉、审判和执行判决等有关的；</li>
          <li>4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</li>
          <li>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</li>
          <li>6、涉及商业秘密的。</li>
        </ul>

        <h4>6、我们如何处理未成年人的个人信息</h4>
        <p>本软件禁止未成年人注册、使用。如我们发现收集了未成年人的个人信息，我们将会设法尽快删除相关数据。</p>

        <h4>7、存储信息的地点和期限，您的个人信息如何在全球范围转移</h4>
        <h3>个人信息存放的地域</h3>
        <p>我们严格按照法律法规的规定，将境内收集和产生的用户个人信息存放于中国境内。</p>
        <h3>个人信息出境的情况</h3>
        <p>
          我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。如果在未来出现个人信息出境的情况，我们将严格遵守中华人民共和国法律法规的相关规定，同时对出境信息的信息安全进行评估，并获取您的明确授权，但是您通过互联网进行跨境发布动态等个人主动行为除外。 针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。
        </p>
        <h3>存储信息的期限</h3>
        <p>一般而言，我们仅在为实现目的所必需的最短时间内保留您的个人信息，超过相应期限内的个人信息将会删除或匿名化进行处理。</p>
        <p>但在下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：</p>
        <ul>
          <li>1.为遵守适用的法律法规或政策等规范性文件的有关规定；</li>
          <li>2.为遵守行政机关或类似授权组织的决定等、司法机关判决、裁定、仲裁机关的裁决或其他法律程序的规定；</li>
          <li>3.我们有理由确信需要遵守法律法规等有关规定；</li>
          <li>4.为执行相关服务协议或本政策、维护社会公共利益，为保护们的客户、我们或我们的关联公司、其他用户或雇员等第三方的人身财产安全或其他合法权益所合理必需的用途。</li>
        </ul>
        <p>当小楼东发生停止运营的情形时，我们将采取推送通知、网站或程序内公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。</p>

        <h4>8、本隐私政策如何更新</h4>
        <p>我们的隐私政策可能变更。如果发生业务功能变更、使用目的变更、个人信息保护相关负责人联络方式变更等情形，我们将相应修订隐私政策。</p>
        <p>
          未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括我们会通过小楼东公示的方式、系统消息等进行通知）。您也可以定期查看本隐私政策，以获取最新的条款。
        </p>
        <p>本政策所指的重大变更包括但不限于：</p>
        <ul>
          <li>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</li>
          <li>2、个人信息共享、转让或公开披露的主要对象发生变化；</li>
          <li>3、您参与个人信息处理方面的权利及其行使方式发生重大变化；</li>
          <li>4、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</li>
          <li>5、个人信息安全影响评估报告表明存在高风险时。</li>
        </ul>
        <p>如果您在接到通知后，选择继续使用我们的服务，即表示同意受经修订的政策约束。 我们还会将本政策的旧版本存档，供您查阅，您可通过前述的联系方式向我们申请查阅。</p>

        <h4>9、如何联系我们/用户申诉渠道和反馈机制</h4>
        <p>如您对本政策或相关事宜有疑问或建议等，可通过小楼东客服进行反馈和申诉。</p>
        <p>我们将妥善受理并及时反馈您的申诉，并在验证您的用户身份后的十五天内予以回复。</p>
        <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>

        <h4>10、广告/我们向您发送的信息</h4>
        <p>我们可能使用您的相关信息，在相关网站、应用、及其他渠道向您发送商业广告。</p>
        <p>如果您不想接受我们给您发送的商业广告，您可随时通过相应产品退订功能取消。</p>

        <h4>11、适用范围</h4>
        <p>小楼东提供的所有服务均使用本隐私政策。但是，请您注意，本隐私政策不适用由其他公司或个人提供的服务，且该服务适用其他公司或个人的隐私政策。</p>
        <p>杭州小楼东科技有限公司</p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/common/header';
import Footer from '@/components/common/footer';
import Tab from '@/components/agreement/tab';
export default {
  components: {
    Header,
    Tab,
    Footer
  },
  data() {
    return {};
  }
};
</script>
<style lang="less">
.policy {
  .content-inner {
    margin: 210px auto 0;
    position: relative;
    width: 61.4%;
    z-index: 2;
    overflow: hidden;
    .main-inner {
      padding: 100px 60px;
      width: 82%;
      float: right;
      background: #fafafa;
      font-size: 18px;
      color: #333333;
      line-height: 36px;
      h1 {
        margin-bottom: 15px;
        font-size: 24px;
        text-align: center;
      }
      h4 {
        margin-bottom: 15px;
      }
      p {
        text-indent: 2em;
        margin-bottom: 15px;
      }
      ul {
        li {
          text-indent: 2em;
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 1661px) {
  .policy {
    .content-inner {
      margin: 150px auto 0;
      width: 75%;
    }
  }
}
@media screen and (max-width: 1441px) {
  .policy {
    .content-inner {
      .main-inner {
        width: 100%;
        float: none;
      }
    }
  }
}
@media screen and (max-width: 751px) {
  .policy {
    .content-inner {
      margin: 0 auto;
      padding: 0 0.15rem;
      width: 100%;
      .main-inner {
        padding: 0;
        background: transparent;
        font-size: 0.14rem;
        font-weight: 400;
        color: #666666;
        line-height: 0.2rem;
        h1 {
          margin-bottom: 0.2rem;
          font-size: 0.2rem;
          font-weight: 600;
          color: #333333;
          line-height: 0.28rem;
        }
        p {
          margin-bottom: 0.1rem;
        }
      }
    }
  }
}
</style>
